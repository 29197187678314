export const useAction = ({ commit, dispatch }) => {
  const setLoading = value => commit("setLoading", value, { root: true });

  const handleError = error => {
    dispatch("setErrorOrThrow", error, { root: true });
  };

  // Full async request pipeline for setting loading,
  // handling errors and returning the successful response.
  // Use optional maxIterations argument to retry the request
  // in case of failure.
  const handleRequest = async (
    request,
    includeLoading = true,
    maxIterations = 1
  ) => {
    let response;

    includeLoading && setLoading(true);

    for (let iteration = 1; iteration <= maxIterations; iteration++) {
      try {
        response = await request();
        if (response) break;
      } catch (error) {
        if (iteration < maxIterations) continue;

        !includeLoading && setLoading(false);
        handleError(error);
      }
    }

    includeLoading && setLoading(false);

    return response;
  };

  return {
    setLoading,
    handleError,
    handleRequest
  };
};
