import ApiService, { insertParams } from "@/util/api.service";

const PRINT_BASE = "packets/{barcode}/print";
const ENDPOINTS = {
  PASSWORD_SEARCH: "password_search",
  PACKET_DELIVERY: "packets/{barcode}/delivery",
  PACKET_RECEIVE: `packets/{barcode}/receive`,
  PACKET_C2C_CONSIGN: "packets/{barcode}/c2c_consign",
  C2C_CONSIGN_DETAIL: "c2c_consigns/{id}",
  RETURNS_FOR_CLIENT: "packets/returns/for_client",
  RETURNS_FOR_CLIENT_DELIVER: "packets/returns/for_client/deliver",
  RETURN_SHIPMENT_RECEIVE: "packets/reclamation_assistant",
  RETURN_SHIPMENT_DETAIL: "reclamation_assistants/{id}",
  PRINT_PACKET_LABEL: `${PRINT_BASE}/return_label`,
  PRINT_RECEIVE_LABEL: `${PRINT_BASE}/receive_label`,
  PRINT_DELIVERY_RECEIPT: `${PRINT_BASE}/delivery_receipt`,
  PRINT_COD_RECEIPT: `${PRINT_BASE}/cod_receipt`,
  PRINT_CONSIGNMENT_RECEIPT: `${PRINT_BASE}/consignment_receipt`
};

export class PacketService extends ApiService {
  // SEARCH
  static postPasswordSearch = password => {
    return this.post(ENDPOINTS.PASSWORD_SEARCH, { password });
  };

  // DELIVERY
  static postPacketDelivery = ({ barcode, ...params }) => {
    return this.post(insertParams(ENDPOINTS.PACKET_DELIVERY, { barcode }), {
      ...params
    });
  };

  static deletePacketDelivery = ({ barcode, ...params }) => {
    return this.delete(insertParams(ENDPOINTS.PACKET_DELIVERY, { barcode }), {
      ...params
    });
  };

  // RECEIVE
  static postPacketReceive = ({ barcode, password }) => {
    return this.post(insertParams(ENDPOINTS.PACKET_RECEIVE, { barcode }), {
      password
    });
  };

  static postC2CConsign = ({ barcode, password }) => {
    return this.post(insertParams(ENDPOINTS.PACKET_C2C_CONSIGN, { barcode }), {
      password
    });
  };

  static getC2CConsignDetail = id => {
    return this.get(insertParams(ENDPOINTS.C2C_CONSIGN_DETAIL, { id }));
  };

  // RETURNS
  static getReturnsForClient = password => {
    return this.get(ENDPOINTS.RETURNS_FOR_CLIENT, { password });
  };

  static postReturnsForClientDeliver = passwordAndBarcodes => {
    return this.post(ENDPOINTS.RETURNS_FOR_CLIENT_DELIVER, {
      ...passwordAndBarcodes
    });
  };

  // RETURN SHIPMENT
  static postReturnShipment = passwordAndCustomer => {
    return this.post(ENDPOINTS.RETURN_SHIPMENT_RECEIVE, {
      ...passwordAndCustomer
    });
  };

  static getReturnShipmentDetail = id => {
    return this.get(insertParams(ENDPOINTS.RETURN_SHIPMENT_DETAIL, { id }));
  };

  // PRINTS
  static postPrintPacketLabel = barcode => {
    return this.post(insertParams(ENDPOINTS.PRINT_PACKET_LABEL, { barcode }));
  };

  static postPrintReceiveLabel = ({ barcode, password }) => {
    return this.post(insertParams(ENDPOINTS.PRINT_RECEIVE_LABEL, { barcode }), {
      password
    });
  };

  static postPrintDeliveryReceipt = barcode => {
    return this.post(
      insertParams(ENDPOINTS.PRINT_DELIVERY_RECEIPT, { barcode })
    );
  };

  static postPrintCodReceipt = barcode => {
    return this.post(insertParams(ENDPOINTS.PRINT_COD_RECEIPT, { barcode }));
  };

  static postPrintConsignmentReceipt = barcode => {
    return this.post(
      insertParams(ENDPOINTS.PRINT_CONSIGNMENT_RECEIPT, { barcode })
    );
  };

  static postPrint = ({ type, barcode }) => {
    return this.post(insertParams(`${PRINT_BASE}/${type}`, { barcode }));
  };
}

export default PacketService;
