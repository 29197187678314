import axios from "axios";
import { delay } from "lodash";
import store from "@/store";
import router from "@/router";
import AuthService from "@/util/auth.service";
import StorageService from "@/util/storage.service";
import { HEADERS } from "@/util/api.service";
import { MOBILE_TYPES } from "@/util/mobile.type";

const MAX_RETRY_COUNT = 5;
const LOGOUT_DELAY = 4000;
let retryCount = 0;

// Inject an axios interceptor for retrying requests and
// refreshing authorization token
export const setAuthTokenRefreshing = () => {
  axios.interceptors.response.use(handleSuccess, handleError);
};

// Reset counts if request succeeds
const handleSuccess = response => {
  retryCount = 0;

  return response;
};

// Evaulate error response and either refresh authorization token
// or retry the original request until the maximum retry count is reached.
const handleError = error => {
  const originalRequest = error.config;

  if (shouldRefreshToken(error)) {
    return refreshAuthToken(originalRequest);
  }

  if (shouldRetryRequest(error)) {
    return retryRequest(originalRequest);
  }

  return Promise.reject(error);
};

// Only retry if the error doesn't have a response prop (expected error)
// and if the retry count hasn't reached the maximum limit
const shouldRetryRequest = error => {
  return !error.response && retryCount < MAX_RETRY_COUNT;
};

// Only request a new auth token if the user is signed in (has token stored),
// the response code was 401 and MAX_RETRY_COUNT hasn't been reached.
const shouldRefreshToken = error => {
  const hasToken = !!StorageService.getToken();

  return (
    error.response?.status === 401 && hasToken && retryCount < MAX_RETRY_COUNT
  );
};

// Get refresh token from storage and use it to request new auth token.
// Then save new refresh and auth tokens and retry the request.
// Logout on failure.
const refreshAuthToken = async originalRequest => {
  try {
    const refreshToken = StorageService.getRefreshToken();
    const { data } = await AuthService.refreshAuthToken(refreshToken);
    const { access_token, refresh_token } = data;

    StorageService.saveToken(access_token);
    StorageService.saveRefreshToken(refresh_token);

    originalRequest.headers[HEADERS.AUTH_TOKEN] = access_token;

    return retryRequest(originalRequest);
  } catch (error) {
    logout(error);
  }
};

// Retry the original request while increasing the retry count
const retryRequest = originalRequest => {
  retryCount++;

  return axios(originalRequest);
};

// Evaluate the current platform and call the right logout function
const logout = error => {
  return store.state.isMobile ? mobileLogout() : desktopLogout(error);
};

// Logout and then call the right mobile function
const mobileLogout = () => {
  store.dispatch("auth/logout", { root: true });

  switch (store.state.platform) {
    case MOBILE_TYPES.ANDROID:
      window.PacketaPPA.onLoggedOut();
      break;
    case MOBILE_TYPES.IOS:
      window.webkit.messageHandlers.onLoggedOut.postMessage({});
      break;
    default:
      break;
  }
};

// Display error and delay logging out by LOGOUT_DELAY value
const desktopLogout = error => {
  store.dispatch("setErrorOrThrow", error.error, { root: true });

  delay(() => {
    store.dispatch("auth/logout", { root: true });
    router.replace({ name: "login" });
  }, LOGOUT_DELAY);
};

export default setAuthTokenRefreshing;
