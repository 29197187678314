import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import VueLogger from "vuejs-logger";
import ApiService from "@/util/api.service";
import StorageService from "@/util/storage.service";
import { isString } from "lodash";
import i18n from "./i18n";
import { initSentry, setupSentryInterceptor } from "./sentry";
import setAuthTokenRefreshing from "@/util/refreshing.token";

Vue.config.productionTip = false;

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: isProduction ? false : true,
  showMethodName: true,
  showConsoleColors: true
};

Vue.use(VueLogger, options);

ApiService.init();

initSentry(Vue);

// Interceptors
setAuthTokenRefreshing();
setupSentryInterceptor();

const setIdentity = mobileIdentity => {
  if (!mobileIdentity) return;

  if (isString(mobileIdentity)) {
    mobileIdentity = JSON.parse(mobileIdentity);
  }

  const data = {
    branch_id: mobileIdentity.support_branch_id,
    id: mobileIdentity.ppb_id,
    email: mobileIdentity.email,
    name: mobileIdentity.name,
    access_token: mobileIdentity.access_token,
    refresh_token: mobileIdentity.refresh_token,
    card_terminal_payments_allowed: mobileIdentity.has_transactions_allowed,
    hermes_print_enabled: mobileIdentity.pairing_active,
    packet_check_enabled: mobileIdentity.packet_check_enabled
  };

  store.commit("auth/storeIdentity", data);
};

const setFeatureFlags = featureFlags => {
  if (!featureFlags) return;

  if (isString(featureFlags)) {
    featureFlags = JSON.parse(featureFlags);
  }

  const { feature_flags: flags } = featureFlags;
  const resultFlags = {};

  flags.forEach(({ name, enabled }) => {
    resultFlags[name] = enabled;
  });

  store.commit("setFeatureFlags", resultFlags);
};

// Call Android
if (window.PacketaPPA) {
  const mobileIdentity = window.PacketaPPA.getUser?.();
  const featureFlags = window.PacketaPPA.getFeatureFlags?.();

  setIdentity(mobileIdentity);
  setFeatureFlags(featureFlags);
}

// Android/iOs call
window.PacketaPPAEvents = {
  featureFlagsChanged: changedFlags => {
    setFeatureFlags(changedFlags);
  },

  toggleSelectPackets: value => {
    store.commit("packet/setToggleSelectionClicked", value);
  },

  onReceiveIdentity: mobileIdentity => {
    setIdentity(mobileIdentity);
  },

  onReceiveSearchCode: searchCode => {
    store.commit("packet/setSearchCode", searchCode);
  },

  onReceiveBarcode: barcode => {
    store.commit("packet/setScannedBarcode", barcode);
  },

  deliveryReturned: success => {
    if (success) {
      store.commit("packet/setReadyForUndelivery", success);
    } else {
      store.dispatch(
        "setErrorOrThrow",
        i18n.t("errorMessages.contact-support"),
        { root: true }
      );
    }
  },

  paymentComplete: success => {
    if (success) {
      store.commit("packet/setPacketPaid", success);
      store.commit("packet/setPaidWithDevice", success);
      store.commit("packet/refreshReadyToPick");
    } else {
      store.dispatch(
        "setErrorOrThrow",
        i18n.t("errorMessages.payment-has-failed"),
        { root: true }
      );
    }
  }
};

// Redirect when not logged in
router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.requiresAuth) &&
    !to.query.platform
  ) {
    if (StorageService.getToken() == null) {
      next({
        name: "login"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
