import { MOBILE_TYPES } from "@/util/mobile.type";

const PLATFORM_PARAM = "platform";
const ANDROID_INTERFACE = "PacketaPPA";

export const isAndroid =
  new URLSearchParams(location.search).get(PLATFORM_PARAM) ==
  MOBILE_TYPES.ANDROID;

export const isAndroidInterface = isAndroid && ANDROID_INTERFACE in window;

export default { isAndroid, isAndroidInterface };
