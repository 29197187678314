import Vue from "vue";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { isAndroidInterface } from "@/util/platform";
import version from "@/util/version";
import StorageService from "@/util/storage.service";
import getActualLanguage from "@/util/language";
import API_URL from "@/util/constants";

// CONSTANTS

export const HEADERS = {
  AUTH_TOKEN: "X-Auth-Token",
  PLATFORM: "X-Client-Platform",
  VERSION: "X-Client-Version",
  TIMEZONE: "X-Client-Timezone",
  UUID: "X-Device-Uuid",
  REFRESH_TOKEN: "X-Refresh-Token",
  LANGUAGE: "Accept-Language"
};

const METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete"
};

// HELPER FUNCTIONS

/**
 * Replaces placeholder params in a URL endpoint string with corresponding values from an object.
 * @example
 *   insertParams("/endpoint/{id}", { id: 1 }); // returns "/endpoint/1"
 * @param {string} endpointRaw - Endpoint string containing placeholders wrapped in curly brackets.
 * @param {object} params - An object where keys correspond to placeholder tokens in the endpoint string, and values are the replacements.
 * @returns {string} An endpoint string with placeholders replaced by actual values.
 */
export const insertParams = (endpointRaw, params) => {
  let endpoint = endpointRaw;

  Object.entries(params).forEach(([param, value]) => {
    endpoint = endpoint.replace(`{${param}}`, value);
  });

  return endpoint;
};

// API SERVICE

export class ApiService {
  static headers = {
    [HEADERS.PLATFORM]: "web",
    [HEADERS.VERSION]: version,
    [HEADERS.TIMEZONE]: Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  static init = () => {
    axios.defaults.baseURL = API_URL;
  };

  static get = (resource, params) => {
    return this.#prepareRequest(METHODS.GET, resource, { params });
  };

  static post = (resource, params) => {
    return this.#prepareRequest(METHODS.POST, resource, params);
  };

  static put = (resource, params) => {
    return this.#prepareRequest(METHODS.PUT, resource, params);
  };

  static patch = resource => {
    return this.#prepareRequest(METHODS.PATCH, resource);
  };

  static delete = (resource, data) => {
    return this.#prepareRequest(METHODS.DELETE, resource, { data });
  };

  static setHeader = (name, value) => {
    this.headers[name] = value;
  };

  static #prepareRequest = async (method, resource, ...additionalData) => {
    const request = `${method.toUpperCase()} ${resource}`;

    try {
      Vue.$log.info(request);
      this.#setHeaders();
      return await axios[method](resource, ...additionalData);
    } catch (error) {
      Vue.$log.error(`ApiService error for ${request}`);
      throw error;
    }
  };

  static #setHeaders = () => {
    Vue.$log.info("Setting headers");

    this.#setLanguageHeader();
    this.#setDeviceUUIDHeader();
    this.#setAuthTokenHeader();
    this.#setAndroidHeaders();

    axios.defaults.headers.common = {
      ...axios.defaults.headers.common,
      ...this.headers
    };
  };

  static #setLanguageHeader = () => {
    this.setHeader(HEADERS.LANGUAGE, getActualLanguage());
  };

  static #setDeviceUUIDHeader = () => {
    let deviceUuid = StorageService.getDeviceUuid();

    if (!deviceUuid) {
      deviceUuid = uuidv4();

      StorageService.saveDeviceUuid(deviceUuid);
    }

    this.setHeader(HEADERS.UUID, deviceUuid);
  };

  static #setAuthTokenHeader = () => {
    const token = StorageService.getToken();

    token && this.setHeader(HEADERS.AUTH_TOKEN, token);
  };

  static #setAndroidHeaders = () => {
    if (!isAndroidInterface) return;

    const androidHeaders = JSON.parse(window.PacketaPPA.getHeaders());
    if (!androidHeaders) return;

    this.headers = {
      ...this.headers,
      ...androidHeaders
    };
  };
}

export default ApiService;
