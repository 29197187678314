import ApiService, { HEADERS } from "./api.service";

const ENDPOINTS = {
  AUTHENTICATE: "users/authenticate",
  REFRESH_TOKEN: "auth/access_token",
  PASSWORD_RESET: "auth/reset_password"
};

export class AuthService extends ApiService {
  static login = credentials => {
    return this.post(ENDPOINTS.AUTHENTICATE, credentials);
  };

  static refreshAuthToken = refreshToken => {
    this.setHeader(HEADERS.REFRESH_TOKEN, refreshToken);

    return this.post(ENDPOINTS.REFRESH_TOKEN);
  };

  static forgotPassword = email => {
    return this.post(ENDPOINTS.PASSWORD_RESET, {
      email: email
    });
  };
}

export default AuthService;
