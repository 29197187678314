import Vue from "vue";
import VueRouter from "vue-router";
import { frontendPrefixRoutes } from "./redirects";

Vue.use(VueRouter);

// Redirect from "/frontend" base path will be added to each route that starts with "/".
// This happens only for browsers. For android devices, instead of redirect, an alias with the same path will be added.
// If you want to exclude some routes from having this alias or redirect,
// add "excludeAlias: true" and/or "excludeRedirect: true" to the route's meta property.
const routes = [
  {
    name: "dashboard",
    path: "/",
    component: () => import("@/views/Dashboard"),
    meta: { requiresAuth: true }
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/Login")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("@/views/ForgotPassword")
  },
  {
    name: "packets-receive-process",
    path: "/packets-receive-process/:batchId",
    component: () => import("@/views/PacketsReceiveProcess"),
    meta: { requiresAuth: true }
  },
  {
    name: "parcels-receive-process",
    path: "/parcels-receive-process/:batchId",
    component: () => import("@/views/ParcelsReceiveProcess"),
    meta: { requiresAuth: true }
  },
  {
    name: "packets-expedition-process",
    path: "/packets-expedition-process/:expeditionId",
    component: () => import("@/views/PacketsExpeditionProcess"),
    meta: { requiresAuth: true }
  },
  {
    name: "packets-inventory-process",
    path: "/packets-inventory-process/:inventoryId",
    component: () => import("@/views/PacketsInventoryProcess"),
    meta: { requiresAuth: true }
  },
  {
    name: "packet-search",
    path: "/packet-search",
    component: () => import("@/views/PacketSearch"),
    meta: { requiresAuth: true }
  },
  {
    name: "packet-detail",
    path: "/packet-detail/:code",
    component: () => import("@/views/PacketDetail"),
    meta: { requiresAuth: true }
  },
  {
    name: "packets-return",
    path: "/packets-return/:code",
    component: () => import("@/views/PacketsReturn"),
    meta: { requiresAuth: true }
  },
  {
    name: "packet-receive",
    path: "/packet-receive/:code",
    component: () => import("@/views/PacketReceive"),
    meta: { requiresAuth: true }
  },
  {
    name: "packet-return-shipment",
    path: "/packet-return-shipment/:code",
    component: () => import("@/views/PacketReturnShipment"),
    meta: { requiresAuth: true }
  },
  {
    name: "account",
    path: "/account",
    component: () => import("@/views/Account"),
    meta: { requiresAuth: true }
  },
  {
    name: "packet-check",
    path: "/packet-check/:code",
    component: () => import("@/views/PacketCheck"),
    meta: { requiresAuth: true }
  },
  {
    name: "packet-age-check",
    path: "/packet-age-check/:code",
    component: () => import("@/views/PacketAgeCheck"),
    meta: { requiresAuth: true }
  },
  {
    name: "not-found",
    path: "*",
    component: () => import("@/views/NotFound")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: frontendPrefixRoutes(routes)
});

export default router;
