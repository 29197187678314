const AUTH_IDENTITY = "auth_identity";
const AUTH_TOKEN = "auth_token";
const REFRESH_TOKEN = "refresh_token";
const CARD_TERMINAL = "card_terminal";
const DEVICE_GATEWAY = "device_gateway";
const DEVICE_UUID = "device_uuid";
const SETTINGS = "settings";

export const getIdentity = () => {
  return JSON.parse(window.localStorage.getItem(AUTH_IDENTITY));
};

export const saveIdentity = identity => {
  window.localStorage.setItem(AUTH_IDENTITY, JSON.stringify(identity));
};

export const clearIdentity = () => {
  window.localStorage.removeItem(AUTH_IDENTITY);
};

export const getToken = () => {
  return window.localStorage.getItem(AUTH_TOKEN);
};

export const saveToken = token => {
  window.localStorage.setItem(AUTH_TOKEN, token);
};

export const clearToken = () => {
  window.localStorage.removeItem(AUTH_TOKEN);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN);
};

export const saveRefreshToken = refreshToken => {
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const clearRefreshToken = () => {
  window.localStorage.removeItem(REFRESH_TOKEN);
};

export const getCardTerminal = () => {
  return JSON.parse(window.localStorage.getItem(CARD_TERMINAL));
};

export const saveCardTerminal = cardTerminal => {
  window.localStorage.setItem(CARD_TERMINAL, JSON.stringify(cardTerminal));
};

export const clearCardTerminal = () => {
  window.localStorage.removeItem(CARD_TERMINAL);
};

export const getDeviceGateway = () => {
  return JSON.parse(window.localStorage.getItem(DEVICE_GATEWAY));
};

export const saveDeviceGateway = deviceGateway => {
  window.localStorage.setItem(DEVICE_GATEWAY, JSON.stringify(deviceGateway));
};

export const clearDeviceGateway = () => {
  window.localStorage.removeItem(DEVICE_GATEWAY);
};

export const getDeviceUuid = () => {
  return window.localStorage.getItem(DEVICE_UUID);
};

export const saveDeviceUuid = deviceUuid => {
  window.localStorage.setItem(DEVICE_UUID, deviceUuid);
};

export const getSettings = () => {
  return JSON.parse(window.localStorage.getItem(SETTINGS));
};

export const saveSettings = settings => {
  window.localStorage.setItem(SETTINGS, JSON.stringify(settings));
};

export const clearSettings = () => {
  window.localStorage.removeItem(SETTINGS);
};

export default {
  getIdentity,
  saveIdentity,
  clearIdentity,
  getToken,
  saveToken,
  clearToken,
  getRefreshToken,
  saveRefreshToken,
  clearRefreshToken,
  getCardTerminal,
  saveCardTerminal,
  clearCardTerminal,
  getDeviceGateway,
  saveDeviceGateway,
  clearDeviceGateway,
  getDeviceUuid,
  saveDeviceUuid,
  getSettings,
  saveSettings,
  clearSettings
};
