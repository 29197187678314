import { PaymentService } from "@/util/payment.service";
import i18n from "@/i18n";
import { useAction } from "@/util/store-helper";

export const TRANSACTION_STATES = {
  CREATED: "created",
  PENDING: "pending",
  CANCELLED: "cancelled",
  FAILED: "failed",
  COMPLETED: "completed",
  TIMED_OUT: "timed_out"
};

export const ERRORS = {
  INVALID_STATE: "invalid_state"
};

const getDefaultState = () => {
  return {
    cardPaymentId: null,
    smsPaymentId: null,
    paymentReturnId: null,
    transactionFailed: false,
    isPending: false
  };
};

const state = getDefaultState();

const getters = {};

const actions = {
  // CARD PAYMENTS
  async createCardPayment({ commit, dispatch }, barcode) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PaymentService.postCardTerminalPayment(barcode);
      const { id } = response.data;

      commit("setCardPaymentId", id);

      return response;
    });
  },

  async getCardPaymentDetail({ commit, dispatch }, id) {
    const { handleRequest, handleError } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PaymentService.getCardTerminalDetail(id);
      const { state: status } = response.data;
      let isPaid = false;

      commit("setIsPending", status === TRANSACTION_STATES.PENDING);

      switch (status) {
        case TRANSACTION_STATES.COMPLETED:
          isPaid = true;
          commit("packet/setPaidByCard", isPaid, { root: true });
          commit("packet/setPacketPaid", isPaid, { root: true });
          commit("packet/refreshReadyToPick", null, { root: true });
          break;

        case TRANSACTION_STATES.CANCELLED:
        case TRANSACTION_STATES.FAILED:
        case TRANSACTION_STATES.TIMED_OUT:
          commit("setTransactionFailed", true);
          handleError(i18n.t("errorMessages.payment-has-failed"));
          break;
      }

      return isPaid;
    });
  },

  async cancelCardPayment({ commit, dispatch }, id) {
    const MAX_RETRIES = 5;
    const { handleRequest } = useAction({ commit, dispatch });

    commit("setIsPending", false);

    return handleRequest(
      async () => await PaymentService.patchCardTerminalCancel(id),
      true,
      MAX_RETRIES
    );
  },

  // SMS PAYMENTS
  async createSMSPayment({ commit, dispatch }, barcode) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PaymentService.postSMSPayment(barcode);
      const { id } = response.data;

      commit("setSMSPaymentId", id);

      return response;
    });
  },

  async getSMSPaymentDetail({ commit, dispatch }, id) {
    const { handleRequest, handleError } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PaymentService.getSMSPaymentDetail(id);
      const { state: status } = response.data;
      let isPaid = false;

      commit("setIsPending", status === TRANSACTION_STATES.PENDING);

      switch (status) {
        case TRANSACTION_STATES.COMPLETED:
          isPaid = true;
          commit("packet/setPacketPaid", isPaid, { root: true });
          commit("packet/refreshReadyToPick", null, { root: true });
          break;

        case TRANSACTION_STATES.CANCELLED:
        case TRANSACTION_STATES.FAILED:
        case TRANSACTION_STATES.TIMED_OUT:
          commit("setTransactionFailed", true);
          handleError(i18n.t("errorMessages.payment-has-failed"));
          break;
      }

      return isPaid;
    });
  },

  async cancelSMSPayment({ commit, dispatch }, id) {
    const MAX_RETRIES = 5;
    const { handleRequest } = useAction({ commit, dispatch });

    commit("setIsPending", false);

    return handleRequest(
      async () => await PaymentService.patchSMSPaymentCancel(id),
      true,
      MAX_RETRIES
    );
  },

  // CASH PAYMENTS
  payInCash({ commit, dispatch }, barcodeAndCod) {
    commit("packet/setPacketPaid", true, { root: true });
    commit("packet/setPaidInCash", true, { root: true });
    commit("packet/refreshReadyToPick", null, { root: true });
    dispatch("createCashPayment", barcodeAndCod);
  },

  async createCashPayment({ commit, dispatch }, barcodeAndCod) {
    const MAX_RETRIES = 5;
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(
      async () => await PaymentService.postCashPayment(barcodeAndCod),
      true,
      MAX_RETRIES
    );
  },

  // PAYMENT RETURNS
  async returnPayment({ commit, dispatch }, barcode) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PaymentService.postPaymentReturn(barcode);
      const { id } = response.data;

      commit("setPaymentReturnId", id);

      return response;
    });
  },

  async getPaymentReturnDetail({ commit, dispatch }, id) {
    const { handleRequest, handleError } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await PaymentService.getPaymentReturnDetail(id);
      const { state: status, failure_reason_text } = response.data;
      const failureStates = [
        TRANSACTION_STATES.FAILED,
        TRANSACTION_STATES.TIMED_OUT
      ];

      if (failureStates.includes(status)) {
        commit("setTransactionFailed", true);
        handleError(failure_reason_text);
      }

      return status === TRANSACTION_STATES.COMPLETED;
    });
  }
};

const mutations = {
  setCardPaymentId(state, id) {
    state.cardPaymentId = id;
    this.commit("clearErrors");
  },

  setSMSPaymentId(state, id) {
    state.smsPaymentId = id;
    this.commit("clearErrors");
  },

  setPaymentReturnId(state, id) {
    state.paymentReturnId = id;
    this.commit("clearErrors");
  },

  setTransactionFailed(state, value) {
    state.transactionFailed = value;
    this.commit("clearErrors");
  },

  setIsPending(state, value) {
    state.isPending = value;
    this.commit("clearErrors");
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  resetTransactionFailed(state) {
    state.transactionFailed = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
