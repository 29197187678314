import Sentry from "@/sentry";
import { AuthService } from "@/util/auth.service";
import StorageService from "@/util/storage.service";
import { useAction } from "@/util/store-helper";

// STATE

const state = {
  identity: StorageService.getIdentity(),
  cardTerminal: StorageService.getCardTerminal(),
  deviceGateway: StorageService.getDeviceGateway(),
  settings: StorageService.getSettings()
};

// GETTERS

const getters = {
  currentUser: state => state.identity?.user || null,
  getBranchId: state => state.identity?.supportBranchId || null,
  isTransactionAllowed: state =>
    state.cardTerminal?.hasTransactionsAllowed ?? false,
  isPairingActive: state => state.deviceGateway?.pairingActive ?? false,
  packetCheckEnabled: state => state.settings?.packetCheckEnabled ?? true
};

// ACTIONS

const actions = {
  async login({ commit, dispatch }, credentials) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await AuthService.login(credentials);

      commit("storeIdentity", response.data);

      return response;
    });
  },

  logout({ commit }) {
    commit("clearIdentity");
    commit("packet/resetState", null, { root: true });
    commit("payment/resetState", null, { root: true });
    commit("scanned/resetState", null, { root: true });
  },

  async forgotPassword({ commit, dispatch }, email) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await AuthService.forgotPassword(email);
    });
  }
};

// MUTATIONS

const mutations = {
  storeIdentity(state, data) {
    const identity = {
      supportBranchId: data.branch_id,
      user: {
        id: data.id,
        email: data.email,
        name: data.name || `${data.first_name} ${data.last_name}`
      }
    };

    const token = data.access_token;
    const refreshToken = data.refresh_token;

    const cardTerminal = {
      hasTransactionsAllowed: data.card_terminal_payments_allowed
    };

    const deviceGateway = {
      pairingActive: data.hermes_print_enabled
    };

    const settings = {
      packetCheckEnabled: data.packet_check_enabled
    };

    state.identity = identity;
    state.cardTerminal = cardTerminal;
    state.deviceGateway = deviceGateway;
    state.settings = settings;

    StorageService.saveIdentity(identity);
    StorageService.saveToken(token);
    StorageService.saveRefreshToken(refreshToken);
    StorageService.saveCardTerminal(cardTerminal);
    StorageService.saveDeviceGateway(deviceGateway);
    StorageService.saveSettings(settings);

    Sentry.setUser({
      email: identity.user.email
    });

    this.commit("clearErrors");
  },

  clearIdentity(state) {
    state.identity = null;
    state.cardTerminal = null;
    state.deviceGateway = null;
    state.settings = null;

    StorageService.clearIdentity();
    StorageService.clearToken();
    StorageService.clearRefreshToken();
    StorageService.clearCardTerminal();
    StorageService.clearDeviceGateway();
    StorageService.clearSettings();

    this.commit("clearErrors");
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
