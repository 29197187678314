export const PRINT_TYPES = {
  COD_RECEIPT: "cod_receipt",
  CONSIGNMENT_RECEIPT: "consignment_receipt",
  DELIVERY_RECEIPT: "delivery_receipt",
  LABEL: "label",
  RETURN_LABEL: "return_label",
  SHELF: "shelf"
};

export default PRINT_TYPES;
